var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "loader",
    },
    [
      _c("span"),
      _c("span"),
      _c("span"),
      _c("div", { staticClass: "loader_msg" }, [
        _vm._v("\n    " + _vm._s(_vm.$t("loading")) + "\n  "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }