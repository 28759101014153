<template>
  <div id="header">
    <div class="soundControl">
      <a
        id="sound-button"
        class="img-btn img-hover-btn helper-btn"
        :class="{ muted: mutedBgSound }"
        @click="toggleSound"
      />
    </div>

    <div
      id="muteNarration"
      class="muteControl"
    >
      <a
        v-show="allowMuteNarration"
        id="mute-button"
        class="img-btn img-hover-btn helper-btn"
        :class="{ muted: mutedNarration }"
        @click="muteNarration"
      />
    </div>

    <a
      v-show="allowFontEdit"
      id="fontsize-button"
      class="img-btn helper-btn"
      @click="toggleFontControls"
    />

    <div
      v-show="fontControlsOpen"
      id="fontControls"
    >
      <a
        v-for="(item) in availableFontSizes"
        :key="item.size"
        :class="{ selected: item.size == fontSize }"
        @click="selectFontSize(item.size)"
      >
        {{ $t(item.size) }}
      </a>
    </div>

    <div
      class="fullscreenControl"
      @click="toggleFullscreen"
    >
      <i :class="isFullscreen ? 'icon-resize-small' : 'icon-resize-full'" />
    </div>

    <PageLoader ref="loader" />
  </div>
</template>

<script>
import PageLoader from '@/components/PageLoader.vue'

export default {
  name: 'Header',
  components: {
    PageLoader
  },
  data() {
    return {
      // This will return true or false depending on if it's full screen or not.
      isFullscreen: document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen,
      allowMuteNarration: false,
      allowFontEdit: false,
      fontControlsOpen: false
    }
  },
  computed: {
    mutedBgSound() {
      return this.$store.getters.mutedBgSound
    },

    mutedNarration() {
      return this.$store.getters.mutedNarration
    },

    fontSize() {
      return this.$store.getters.fontSize
    },

    availableFontSizes() {
      return [
        { size: 'small' },
        { size: 'medium' },
        { size: 'large' }
      ]
    }
  },
  methods: {
    selectFontSize(size) {
      this.$store.commit('setUserFontSize', size)
      this.toggleFontControls()
      this.sendInteractionToGA('changed_fontsize', size)
    },

    toggleFontControls() {
      this.fontControlsOpen = !this.fontControlsOpen
    },

    toggleSound() {
      this.sideBtnSound.play()

      this.$store.commit('setUserMutedBgSound', !this.mutedBgSound)

      if (this.continueSound) this.continueSound.mute(this.mutedBgSound)
      if (this.bestChoiceSound) this.bestChoiceSound.mute(this.mutedBgSound)
      if (this.worstChoiceSound) this.worstChoiceSound.mute(this.mutedBgSound)
      if (this.sideBtnSound) this.sideBtnSound.mute(this.mutedBgSound)
      if (this.bgSound) this.bgSound.mute(this.mutedBgSound)
      if (this.narrationBgSound) this.narrationBgSound.mute(this.mutedBgSound)

      this.$store.dispatch('setSound', this.mutedBgSound)
      this.sendInteractionToGA('muted_bgsound', this.mutedBgSound)
    },

    muteNarration() {
      this.sideBtnSound.play()

      this.$store.commit('setUserMutedNarration', !this.mutedNarration)

      if (this.narrationSound) this.narrationSound.mute(this.mutedNarration)

      this.$store.dispatch('setNarration', this.mutedNarration)

      this.sendInteractionToGA('mute_narration', this.mutedNarration ? 'muted_sound' : 'unmuted_sound')
    },

    toFullscreen() {
      this.launchIntoFullscreen()
      this.isFullscreen = true
      this.sendInteractionToGA('clicked_fullscreen', 'enter_fullscreen')
    },

    exitFullscreen() {
      this.exitFromFullscreen()
      this.isFullscreen = false
      this.sendInteractionToGA('clicked_fullscreen', 'exit_fullscreen')
    },

    toggleFullscreen() {
      if (this.isFullscreen) {
        this.exitFullscreen()
      }
      else {
        this.toFullscreen()
      }
    }
  }
}
</script>
