<template>
  <div
    v-show="loading"
    class="loader"
  >
    <span />
    <span />
    <span />

    <div class="loader_msg">
      {{ $t("loading") }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageLoader',
  computed: {
    loading() {
      return this.$store.getters.loading
    }
  }
}
</script>
