var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container no-overflow",
      class: {
        "bg-isolated": _vm.bgIsolated,
        "question-playing": _vm.questionPlaying,
      },
      attrs: { id: "game-container" },
    },
    [
      _c("Header", { ref: "header" }),
      _c("HeadphonesLoader", { attrs: { loading: _vm.headphonesLoading } }),
      _c("main", { staticClass: "relative" }, [_c("router-view")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }