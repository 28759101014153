var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { name: "custom-fade", "leave-active-class": "animated fadeOut" },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "bg_screen",
          attrs: { id: "headphones-loader" },
        },
        [
          _c("img", {
            staticClass: "headphones animated infinite pulse",
            attrs: { src: require("@/assets/img/headphones.png"), alt: "" },
          }),
          _c("div", { staticClass: "headphones-box" }, [
            _c("p", [_vm._v(_vm._s(_vm.$t("headphones")))]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }