export default {
  'DECISION_MAKING': 'Oracle',
  'RESILIENCE': 'Temperus',
  'ADAPTABILITY': 'Fabella',
  'FLEXIBILITY': 'Sylva',
  'ACCOUNTABILITY': 'Lumina',
  'INTEGRITY': 'Veritas',
  'TEAMWORK': 'Morbida',
  'LEARNING_AGILITY': 'Alchimeia',
  'PROJECT_MANAGEMENT': 'judgement',
  'ENTREPRENEURSHIP': 'chance',
  'PEOPLE_SUPPORT': 'solace'
}
