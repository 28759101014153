<template>
  <div
    id="game-container"
    class="container no-overflow"
    :class="{ 'bg-isolated': bgIsolated, 'question-playing': questionPlaying }"
  >
    <Header ref="header" />

    <HeadphonesLoader :loading="headphonesLoading" />

    <main class="relative">
      <router-view />
    </main>
  </div>
</template>

<script>
import HeadphonesLoader from '@/components/HeadphonesLoader.vue'
import Header from '@/components/Header.vue'
import Cookies from '@/cookies/cookies'

export default {
  name: 'GameContainer',
  components: {
    HeadphonesLoader,
    Header
  },
  data() {
    return {
      currentSoftSkill: null,
      headphonesLoading: true,
      bgIsolated: false,
      questionPlaying: false,
      gameStarted: false,
      chaptersStarted: false,
      isChapterStart: true,
      isChapterEnd: false,
      isGameEnd: false,
      chaptersFinished: false
    }
  },
  computed: {
    questionIdx() {
      return this.$store.getters.questionIdx
    },
    gameplay() {
      return this.$store.getters.gameplay
    },
    avatar() {
      return this.$store.getters.avatar
    },
    avatars() {
      return this.gameplay.avatarSelection.avatarList
    },
    avatarsDB() {
      return TAFFY(this.gameplay.avatarSelection.avatarList)
    },
    softSkills() {
      return this.gameplay.softSkills
    },
    softSkillsInvited() {
      return this.gameplay.softSkillsInvited
    },
    availableIslandsTotal() {
      return this.$store.getters.availableIslandsTotal
    },
    completedAvailableIslands() {
      return this.softSkillIdx >= this.availableIslandsTotal
    },
    hasVideoIntro() {
      return this.$store.getters.hasVideoIntro
    },
    hasVideoOutro() {
      return this.$store.getters.hasVideoOutro
    }
  },
  created() {
    this.$store.dispatch('getGameplay')
      .then(res => {
        this.initGame(res)

        this.$store.commit('setBgSound', this.getHowlBtnSound('intro_music'))
        this.$store.commit('setBestChoiceSound', this.getHowlBtnSound('bestChoice'))
        this.$store.commit('setWorstChoiceSound', this.getHowlBtnSound('worstChoice'))
        this.$store.commit('setSideBtnSound', this.getHowlBtnSound('sideButton'))
        this.$store.commit('setContinueSound', this.getHowlBtnSound('continueButton'))

        setTimeout(() => {
          this.headphonesLoading = false
          this.routeGame()
        }, 1500) // @TODO
      })
  },
  methods: {
    initGame(res) {
      window.removeEventListener('scroll', window.scrollTo(0, 0))
      this.$store.commit('setGameData', this.processGameplay(res.data))
      this.initIslands()

      const hasAvatar = this.varExists(res.data.gameplayProfile.avatarId)
      if (hasAvatar) {
        this.initAvatar()
      }
      if (res.data.gameplayProfile.currentQuestion > 0) {
        this.chaptersStarted = true
        this.preloadBgImages()
        this.initCurrentQuestion()
      }
      else if (hasAvatar) {
        this.preloadAvatarImage('big')
        this.preloadBgImages()
      }
    },

    initIslands() {
      const islands = []
      const availableIslands = require('@/config/AvailableIslands').default
      const bundledSkills = []

      this.softSkills.forEach(softSkill => {
        bundledSkills.push(softSkill.name)
        islands.push({
          skill: softSkill.name,
          name: availableIslands[softSkill.name]
        })
      })

      Object.entries(availableIslands).forEach(([softSkill, island]) => {
        if (!bundledSkills.includes(softSkill)) {
          islands.push({
            skill: softSkill,
            name: island
          })
        }
      })

      this.$store.commit('setIslands', islands)
    },

    initAvatar() {
      this.$store.commit('setGameAvatar', this.avatarsDB({ id: this.gameplay.gameplayProfile.avatarId }).first())
      this.$store.commit('setUserData', this.gameplay.gameplayProfile)
    },

    initCurrentQuestion() {
      this.chaptersFinished = true

      for (let softSkillIndex = 0; softSkillIndex < this.softSkills.length; softSkillIndex++) {
        const softSkill = this.softSkills[softSkillIndex]

        if (softSkill.status !== 'FINISHED') {
          for (let questionIndex = 0; questionIndex < softSkill.questions.length; questionIndex++) {
            const question = softSkill.questions[questionIndex]

            if (!question.answered) {
              this.chaptersFinished = false
              this.isChapterStart = false
              this.$store.commit('setCurrentQuestion', question)
              this.$store.commit('setSoftSkillIdx', softSkillIndex)
              this.$store.commit('setQuestionIdx', questionIndex - 1)
              break
            }
          }
          break
        }
      }
    },

    setCurrentQuestion() {
      this.currentSoftSkill = this.softSkills[this.softSkillIdx]

      let currentQuestion = false
      if (this.varExists(this.currentSoftSkill.questions[this.questionIdx])) {
        currentQuestion = this.proccessCurrentQuestion(this.currentSoftSkill.questions[this.questionIdx])
      }

      this.$store.commit('setCurrentQuestion', currentQuestion)
    },

    proccessCurrentQuestion(currentQuestion) {
      if (currentQuestion !== false) {
        currentQuestion.cssClass = this.varExists(currentQuestion.cssClass) ? currentQuestion.cssClass : this.currentSoftSkill.cssClass

        const bgVersion = 'bg' + this.imgSize
        currentQuestion.bgImg = this.varExists(currentQuestion[bgVersion]) ? currentQuestion[bgVersion] : this.currentSoftSkill[bgVersion]
        currentQuestion.prevprogress = 0
        currentQuestion.progress = 0

        if (this.questionIdx > 0) {
          currentQuestion.prevprogress = ((this.questionIdx - 1) / this.currentSoftSkill.questionCount) * 100
          currentQuestion.progress = (this.questionIdx / this.currentSoftSkill.questionCount) * 100
        }

        this.$store.commit('setNarrationPathAac', currentQuestion.narrationPathAac)
        this.$store.commit('setNarrationPathOgg', currentQuestion.narrationPathOgg)
      }

      return currentQuestion
    },

    preloadBgImages() {
      const currentQuestion = this.gameplay.gameplayProfile.currentQuestion
      const imgArray = []

      Object.entries(this.gameplay.softSkills).forEach(([i, softSkill]) => {
        Object.entries(softSkill.questions).forEach(([j, question]) => {
          if (currentQuestion > 0 && currentQuestion >= question.id) {
            return true
          }
          imgArray.push(question)
        })
      })

      this.sequentialImgPreload(imgArray, 'bg' + this.imgSize)
    },

    preloadAvatarImage(size) {
      const avatarID = this.gameplay.gameplayProfile.avatarId

      Object.entries(this.avatars).forEach(([i, avatar]) => {
        if (avatarID > 0 && avatarID !== avatar.id) {
          return true
        }

        const img = new Image()
        img.src = avatar[size + this.imgSize]
      })
    },

    routeGame() {
      if (this.chaptersFinished) {
        if (this.isGameEnd) {
          const gameStartTime = Cookies.get('game_start_time')
          const gameSeconds = (gameStartTime !== '') ? Math.round((Date.now() - gameStartTime) / 1000) : 0
          this.sendGameProgressToGA('game_ended', this.$store.getters.playerResultId, gameSeconds)
          this.$router.replace('/game/end')
        }
        else {
          let finishEarly = false
          if (this.softSkills.length > this.softSkillsInvited.length && this.softSkillIdx === this.softSkillsInvited.length) {
            finishEarly = true
          }

          this.$store.dispatch('setGameComplete', {
            playerResultId: this.$store.getters.playerResultId,
            softSkillsInvited: finishEarly ? this.softSkillsInvited : this.softSkills
          }).then(res => {
            if (finishEarly) {
              return
            }

            this.isGameEnd = true

            if (this.hasVideoOutro) {
              this.resetNarrationSound()
              this.$router.replace('/game/video')
            }
            else {
              this.$router.replace('/game/book')
            }
          })

          if (finishEarly) {
            this.$router.replace({ path: '/game/end', query: { finishEarly: true } })
          }
        }
      }
      else {
        if (this.varExists(this.avatar)) {
          let softskillContainer = this.softSkills
          let softSkillSelector = this.softSkills[this.softSkillIdx]
          if (this.varExists(this.softSkillsInvited[this.softSkillIdx])) {
            softskillContainer = this.softSkillsInvited
            softSkillSelector = this.softSkillsInvited[this.softSkillIdx]
          }
          if (!this.varExists(softSkillSelector.questions[this.questionIdx + 1])) {
            this.$store.commit('setQuestionIdx', -1)
            this.isChapterEnd = true
            this.$router.replace('/game/book')
          }
          else if (this.isChapterEnd) {
            if (this.completedAvailableIslands) {
              this.$router.replace({ path: '/game/end', query: { noMoreAvailableThisMonth: true } })
              return
            }
            if (this.gameplay.gameBreaker) {
              if (this.softSkillIdx === 1 || this.softSkillIdx === 4) {
                const step = (this.softSkillIdx === 1) ? 'step-2' : 'step-3'
                this.$router.replace({ path: '/game/transition', query: { step } })
                return
              }
            }

            const nextSoftSkillIdx = this.softSkillIdx + 1
            this.isChapterEnd = false
            this.isChapterStart = true
            this.chaptersFinished = !this.varExists(softskillContainer[nextSoftSkillIdx])
            this.sendGameProgressToGA('chapter_ended', softSkillSelector.name, softskillContainer)
            this.$store.commit('setSoftSkillIdx', nextSoftSkillIdx)
            if (this.chaptersFinished) {
              this.routeGame()
            }
            else {
              this.$router.replace('/game/world_map')
            }
          }
          else if (!this.chaptersStarted) {
            this.chaptersStarted = true
            this.$router.replace('/game/world_map')
          }
          else if (this.isChapterStart) {
            this.isChapterStart = false
            this.$router.replace('/game/book')
          }
          else {
            this.proceedToNextQuestion()
          }
        }
        else if (this.gameStarted) {
          this.$router.replace('/game/avatar_selection')
        }
        else {
          this.gameStarted = true
          this.proceedToGameIntro()
        }
      }
    },

    proceedToGameIntro() {
      Cookies.set('game_start_time', Date.now(), 1)

      this.preloadAvatarImage('small')

      if (this.hasVideoIntro) {
        this.$router.replace('/game/video')
      }
      else {
        this.$router.replace('/game/book')
      }
    },

    proceedToNextQuestion() {
      if (this.completedAvailableIslands) {
        this.$router.replace({ path: '/game/end', query: { noMoreAvailableThisMonth: true } })
        return
      }
      this.$store.commit('setQuestionIdx', this.questionIdx + 1)
      this.setCurrentQuestion()
      this.$router.replace('/game/question')
    }
  }
}
</script>
<style src="@/assets/css/jquery.qtip.min.css"></style>
<style src="@/assets/css/jquery-ui.structure.min.css"></style>
<style src="@/assets/css/jquery.mCustomScrollbar.min.css"></style>
