var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "header" } },
    [
      _c("div", { staticClass: "soundControl" }, [
        _c("a", {
          staticClass: "img-btn img-hover-btn helper-btn",
          class: { muted: _vm.mutedBgSound },
          attrs: { id: "sound-button" },
          on: { click: _vm.toggleSound },
        }),
      ]),
      _c(
        "div",
        { staticClass: "muteControl", attrs: { id: "muteNarration" } },
        [
          _c("a", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.allowMuteNarration,
                expression: "allowMuteNarration",
              },
            ],
            staticClass: "img-btn img-hover-btn helper-btn",
            class: { muted: _vm.mutedNarration },
            attrs: { id: "mute-button" },
            on: { click: _vm.muteNarration },
          }),
        ]
      ),
      _c("a", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.allowFontEdit,
            expression: "allowFontEdit",
          },
        ],
        staticClass: "img-btn helper-btn",
        attrs: { id: "fontsize-button" },
        on: { click: _vm.toggleFontControls },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.fontControlsOpen,
              expression: "fontControlsOpen",
            },
          ],
          attrs: { id: "fontControls" },
        },
        _vm._l(_vm.availableFontSizes, function (item) {
          return _c(
            "a",
            {
              key: item.size,
              class: { selected: item.size == _vm.fontSize },
              on: {
                click: function ($event) {
                  return _vm.selectFontSize(item.size)
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t(item.size)) + "\n    ")]
          )
        }),
        0
      ),
      _c(
        "div",
        {
          staticClass: "fullscreenControl",
          on: { click: _vm.toggleFullscreen },
        },
        [
          _c("i", {
            class: _vm.isFullscreen ? "icon-resize-small" : "icon-resize-full",
          }),
        ]
      ),
      _c("PageLoader", { ref: "loader" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }