<template>
  <transition
    name="custom-fade"
    leave-active-class="animated fadeOut"
  >
    <div
      v-show="loading"
      id="headphones-loader"
      class="bg_screen"
    >
      <img
        class="headphones animated infinite pulse"
        src="@/assets/img/headphones.png"
        alt
      >
      <div class="headphones-box">
        <p>{{ $t("headphones") }}</p>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'HeadphonesLoader',
  props: {
    loading: {
      type: Boolean
    }
  }
}
</script>
